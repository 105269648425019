'use client';

import { cn } from '@repo/ui';
import React, { useState, useEffect } from 'react';

const ContactUsButton: React.FC<{ className?: string }> = ({ className }) => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
        };

        checkMobile();
        window.addEventListener('resize', checkMobile);

        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    const handleClick = () => {
        const phoneNumber = '+40356630630';
        const message = `Hello, I'm interested in wikoti | menus. Please contact me about your services.`;
        const mailtoLink = `mailto:contact@wikoti.com?subject=Inquiry about wikoti | menus&body=${encodeURIComponent(message)}`;

        if (isMobile) {
            // Mobile device - use tel: protocol
            window.location.href = `tel:${phoneNumber}`;
        } else {
            // Desktop - open email client
            window.location.href = mailtoLink;
        }
    };

    return (
        <button
            onClick={handleClick}
            className={cn('bg-wikotiNav border-2 border-wikotiNavText text-wikotiNavText px-10 py-4 rounded-full text-lg font-semibold hover:bg-wikotiNavText hover:text-wikotiNav transition-all duration-300 transform hover:scale-105 shadow-lg inline-flex items-center justify-center', className)}
        >
            {isMobile ? "Call wikoti" : "Contact wikoti"}
        </button>
    );
};

export default ContactUsButton;