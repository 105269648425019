'use client';

import React from 'react';

const CheckPriceButton: React.FC = () => {
    const handleClick = () => {
        document.getElementById('pricing')?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <button
            onClick={handleClick}
            className="bg-wikotiAction text-wikotiActionText px-10 py-4 rounded-full text-lg font-semibold hover:opacity-90 transition-opacity transform hover:scale-105"
        >
            Check price
        </button>
    );
};

export default CheckPriceButton;